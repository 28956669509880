<template>
  <page-header-wrapper class="month-discount" :title='this.$route.meta.title'>
    <a-tabs default-active-key="1" :tabBarStyle="{background: '#fff'}">
      <a-tab-pane key="1" :tab="'订单出库' + '(' + orderTotal + '笔)'">
        <order-list @setOrderTitle="setOrderTitle"></order-list>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="'活动出库' + (activityTotal > -1 ? '(' + activityTotal + '笔)' : '') ">
        <activity-list @setActvityTitle="setActvityTitle"></activity-list>
      </a-tab-pane>
      <a-tab-pane key="3" :tab="'兑换订单' + (exchangeTotal > -1 ? '(' + exchangeTotal + '笔)' : '') ">
        <exchange-list @setExchangeTitle="setExchangeTitle"></exchange-list>
      </a-tab-pane>
      <a-tab-pane key="4" :tab="'分公司订单' + (officeTotal > -1 ? '(' + officeTotal + '笔)' : '') ">
        <office-list @setOfficeTitle="setOfficeTitle"></office-list>
      </a-tab-pane>
      <a-tab-pane key="5" :tab="'会员订单' + (memberOrderTotal > -1 ? '(' + memberOrderTotal + '笔)' : '') ">
        <memberOrder-list @setMemberOrderTitle="setMemberOrderTitle"></memberOrder-list>
      </a-tab-pane>
    </a-tabs>
  </page-header-wrapper>
</template>

<script>
import OrderList from "./OrderList.vue"
import ActivityList from "./ActivityList.vue"
import ExchangeList from "./ExchangeList.vue"
import OfficeList from "./OfficeList.vue"
import MemberOrderList from "./MemberOrderList.vue"

import {
  queryActivityOrder,
  queryActivityOrder499,
  queryAreaOrder,
  queryExchangeOrder,
  queryMemberOrder1586
} from "@/api/member-order";
import moment from "moment";

const formatType = 'YYYY-MM-DD HH:mm';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'InsideStorehouseList',
  components: {
    OrderList, ActivityList,ExchangeList,OfficeList,MemberOrderList
  },
  data() {
    return {
      orderTotal: 0,
      activityTotal: -1,
      memberOrderTotal: -1,
      exchangeTotal:-1,
      officeTotal:-1
    }
  },
  created() {
    this.handleInitial();
  },
  methods: {
    setOrderTitle(value) {
      this.orderTotal = value
    },
    
    setActvityTitle(value) {
      this.activityTotal = value
    },
    setMemberOrderTitle(value) {
      this.memberOrderTotal = value
    },
    setExchangeTitle(value) {
      this.exchangeTotal = value
    },
    setOfficeTitle(value) {
      if(value && value>0){
    //  子页面如果有类型返回，那么显示当前类型数据
        this.officeTotal = value;
      }else{
        //默认搜索，全部数据
        let jfParam = Object.assign({areaOrderType:1,start:baseStart,end:baseEnd});
        queryAreaOrder(jfParam).then(datum1 => {
          let jfTotal = datum1.total;
          let dhParam = Object.assign({areaOrderType:2,start:baseStart,end:baseEnd});
          queryAreaOrder(dhParam).then(datum2 => {
            let dhTotal = datum2.total;
            let totalAll = jfTotal +dhTotal;
            this.officeTotal = totalAll
          })
        })
      }

    },
    handleInitial(){
      this.setOfficeTitle();

      this.setMemberOrderTitle(0);

      let params = Object.assign( {start:baseStart,end:baseEnd});
      queryActivityOrder(params).then(datum => {
          let total = datum.total;
         this.setActvityTitle(total );
        });
      queryExchangeOrder(params).then(datum => {
          // debugger;
          let total = datum.ext1;
          this.setExchangeTitle(total );
        });

      queryActivityOrder499(params).then(datum => {
          let total = datum.total;
          this.setActvityTitle(total);
        })

      queryMemberOrder1586(params).then(datum => {
        let total = datum.total;
        this.setMemberOrderTitle(total);
      })

    }

  },
}
</script>
<style lang="less">
.month-discount {
  .ant-tabs-nav-wrap {
    padding: 0 26px;
  }
  .ant-tabs-bar {
    margin: unset !important;
  }
  >.ant-pro-grid-content>.ant-pro-page-header-wrap-children-content {
    margin: unset !important;
  }
}
</style>
